'use strict';
import debounce from 'lodash/debounce';
import '@fontsource/open-sans/latin.css';

import './css/tailwind.css';

// quick polyfill to add `forEach` support to dom methods for browsers that don't yet support it
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (window.HTMLCollection && !window.HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

// setup function to run on load, to handle filtering
const onLoad = function() {
	const body = document.body;
	body.classList.remove('javascriptDisabled');
	body.classList.add('javascriptEnabled');

	const categories = document.querySelectorAll('.items');
	const versions = document.querySelectorAll('.item');
	const versionsAds = document.querySelectorAll('.item.nodecraft');
	const noVersionsFound = document.querySelectorAll('.noVersionsFound');
	const versionSearch = document.querySelector('#versionFilter');
	const yearSearch = document.querySelector('#yearFilter');
	if (!versionSearch || !yearSearch) {
		return; // not on a page with a filter, do nothing further
	}

	const filterFunction = debounce(function filterFunction() {
		for (const element of noVersionsFound) {
			element.classList.add('hidden');
		}
		const searchTerm = String(versionSearch.value).toLowerCase();
		const searchYear = String(yearSearch.value).toLowerCase();

		// hide ads when searching
		for (const element of versionsAds) {
			element.classList.remove('hidden');
		}
		// hide/show versions
		for (const element of versions) {
			element.classList.remove('hidden');
		}
		const filtered = Array.prototype.filter.call(versions, function(element) {
			// always hide ads when filtering
			if ((searchTerm || searchYear) && element.classList.contains('nodecraft')) {
				return true;
			}
			return (!String(element.dataset.version).toLowerCase().includes(searchTerm) || !String(element.dataset.year).toLowerCase().includes(searchYear));
		});
		for (const element of filtered) {
			element.classList.add('hidden');
		}

		// for each column, check if there's no versions to show, and show the no files message
		for (const element of categories) {
			const versions = element.querySelectorAll('.item:not(.hidden)');
			if (!versions || versions.length === 0) {
				const categoryNoVersionsFound = element.querySelector('.noVersionsFound');
				categoryNoVersionsFound.classList.remove('hidden');
			}
		}

		// dynamically update year filter text
		const optionText = yearSearch.querySelector('#yearOption');
		if (searchYear) {
			// we're filtering by year, set the text on the year filter option to clear
			optionText.textContent = '-- Clear Filter --';
		} else {
			optionText.textContent = '-- Filter by Year --';
		}
	}, 300, { leading: true, trailing: true });
	versionSearch.addEventListener('keyup', filterFunction);
	yearSearch.addEventListener('change', filterFunction);
	setTimeout(filterFunction, 100); // run once on page load, for example when back button is hit and `change` isn't triggered. This is more of a browser bug, but this band-aids the effects
};

// handle async JavaScript - this file shouldn't ever load before `DOMContentLoaded` in modern browsers as it's loaded async, but can in older browsers, or race-conditions
if (document.readyState !== 'loading') {
	onLoad();
} else {
	document.addEventListener('DOMContentLoaded', onLoad);
}
